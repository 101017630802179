body,
html {
	background: rgb(223, 223, 223);
	background: linear-gradient(
		90deg,
		rgba(223, 223, 223, 1) 0%,
		rgba(232, 232, 232, 1) 100%
	);
}

.App {
	text-align: center;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	text-align: left;
	margin: 0px;
}
h1 {
	font-size: 2em;
	font-weight: 600;
}
h2 {
	font-size: 2em;
	font-weight: 300;
}
h3 {
	font-size: 1.5em;
	font-weight: 600;
}
h4 {
	font-size: 1.5em;
	font-weight: 300;
}
h5 {
	font-size: 1.2em;
	font-weight: 600;
}
h6 {
	font-size: 1em;
	font-weight: 600;
}
