.typeSelection {
	background: #ededed;
	padding: 20px;
}
.addQuestionContainer {
	border: 1px solid #ededed;
}

.MultipleChoiceAnswer {
	padding: 20px;

	display: flex;
}
.MultipleChoiceAnswerContainer {
	padding: 20px;
}
.MultipleChoiceText {
	padding: 20px;
}
