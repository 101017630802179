.contentblock_preview {
	background: white;
	width: 250px;
	height: 175px;
	margin: 10px auto;
	padding: 15px;
}
.previewBlock {
	font-size: 8px;
	line-height: 10px;
	font-style: normal;
	color: #333333;
	padding: 5px;
	text-align: left;
	text-decoration: none;
}
.previewBlock h2,
.previewBlock h1,
.previewBlock h3 {
	height: auto;
}
.container a {
	text-decoration: none;
}
