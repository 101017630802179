.imageLeft {
	height: 80vh;
	flex: 0 1 auto;
	width: 30%;
	background-position: center;
	background-size: cover;
	padding-top: 40px;
}
.imageFull {
	height: 80vh;
	flex: 0 1 auto;
	width: 100%;
	background-position: center;
	background-size: cover;
	padding-top: 40px;
}
.textRight {
	flex: 0 1 auto;
	width: 59%;
	height: 80vh;
}
.textFull {
	flex: 0 1 auto;
	width: 100%;
	height: 80vh;
}
.textOverlay {
	flex: 0 1 auto;
	width: 60%;
	height: 50vh;
	margin-left: auto;
	margin-right: auto;
}
.contentWrapper {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	width: 900px;
	margin-left: auto;
	margin-right: auto;
}
.ck-editor__editable {
	height: 80vh;
}
.ck-content {
	height: 80vh;
}
