/* material ui customizing */
.MuiCard-root {
	position: relative;
}
.MuiCardActions-root {
	position: absolute;
	bottom: 0px;
}
.movingDropzone {
	background: rgba(255, 255, 255, 0.1) !important;
}
.movingDropzone:hover {
	background: green !important;
}
.movingState {
	opacity: 0.5;
}
.card {
	background: white;
	width: 225px;
	height: 250px;
	padding: 20px;
	border-radius: 2px;
	margin: 0px 0px 25px 0px;
	-webkit-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease-in-out;
}
.card:hover {
	/*	-webkit-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
	box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
*/
	width: 229px;
	height: 254px;
	margin: -2px -2px 23px -2px;
	transition: all 0.05s ease-in-out;
}
.linkArea {
	height: 180px;
	display: block;
}
.card h2 {
	margin: 0px;
	display: block;
	height: 70px;
	font-size: 1.5em;
	font-weight: 300;
	text-align: center;
}
.card p {
	font-size: 10px;
	text-align: center;
}
.card a {
	color: #333333;
	text-decoration: none;
}
.iconCircleModule,
.iconCircleFolder {
	border-radius: 50%;
	height: 50px;
	width: 50px;
	margin: 0px auto;
	text-align: center;
	padding-top: 7px;
}
.iconCircleModule {
	background: #3f51b5;
}
.iconCircleFolder {
	background: #333333;
}
.iconCircleModule svg,
.iconCircleFolder svg {
	color: white;
}
