.menuBar {
	height: 50px;
	background: #ffffff;
	color: #333333;
	width: 100%;
	left: 0px;
	right: 0px;
	z-index: 10;
	position: relative;
	display: flex;
}
.menuContainer {
	display: flex;
	flex: 0 1 1000px;
	max-width: 1000px;
	margin: 0px auto;
	justify-content: space-between;
}
.btn_container {
	display: flex;
	max-width: 800px;
	flex: 0 1 800px;
	justify-content: flex-end;
}
.logo_container {
	display: flex;
	flex: 0 1 auto;
	max-width: 1000px;
	margin: 2px 0px 0px 0px;
	color: #f36904;
	font-size: 28px;
	font-weight: 300;
}
