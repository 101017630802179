.landing_container {
	position: relative;
	width: 100vw;
	height: 100vh;
}

.test {
	background: red;
}
.card_container {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-left: auto;
	margin-right: auto;
	max-width: 1000px;
}
.emptySpaceBefore {
	width: 25px;
	background: grey;
	height: 250px;
	flex: 0 0 auto;
	margin-left: -25px;
}
.emptySpaceBeforeUnactive {
	width: 25px;
	background: transparent;
	height: 250px;
	flex: 0 0 auto;
	margin-left: -25px;
}
.emptySpaceAfter {
	width: 25px;
	background: grey;
	height: 250px;
	flex: 0 0 auto;
}
.emptySpaceAfter:hover,
.emptySpaceBefore:hover {
	background: green;
}
.emptySpaceAfterUnactive {
	width: 25px;
	background: transparent;
	height: 250px;
	flex: 0 0 auto;
	content: "";
}
.cardWrapper {
	display: flex;
	height: 250px;
	margin: 0px 0px 25px 0px;
}
.overlayMove {
	position: absolute;
	width: 250px;
	margin-top: 100px;
	text-align: center;
	z-index: 99;
}
